.App {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  direction: rtl;
  background-image: url("./assets/bg.jpg");
  background-attachment: fixed;
  background-color: #f0f0f0;
}

.picker {
  margin: 150px 33% 0 0;

  .block {
    margin: 20px 20px 40px 20px;

    h1 {
      font-size: 20px;
      font-weight: 600;
      clear: both;
      text-align: right;
    }

    .gap {
      float: right;
      margin: 0 0 10px 10px;

      .tile {
        border: 3px solid transparent;
        width: 165px;
        height: 165px;
        border-radius: 8px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tile.active {
        border-color: rgb(251, 255, 0);
        -moz-box-shadow: 0 0 5px 5px #bbb;
        -webkit-box-shadow: 0 0 5px 5px #bbb;
        box-shadow: 0 0 5px 5px #bbb;
      }
    }
  }
}
.progress {
  position: fixed;
  left: 50%;
  top: 50%;
}
.fullscreen-enabled .player {
  width: 100%;
}
.player {
  top: 0;
  right: 0;
  height: 100%;
  width: 33%;
  position: fixed;
  z-index: 100;
  overflow: hidden;
  background-color: #132f54;
  color: white;

  .logo {
    position: absolute;
    left: 30px;
    top: 20px;
    font-size: 16px;
    font-weight: 700;
    z-index: 100;
    line-height: 30px;
    color: #f8f301;

    img {
      width: auto;
      height: auto;
      position: relative;
      top: 10px;
      vertical-align: middle;
    }
  }
  .patefon {
    width: 325px;
    height: 328px;
    position: absolute;
    left: 50%;
    top: 20%;
    margin-left: -170px;
    z-index: 200;

    .pinter {
      position: absolute;
      -ms-transform: rotate(0deg); /* IE 9 */
      -ms-transform-origin: 20px 10px; /* IE 9 */
      -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
      -webkit-transform-origin: 20px 10px; /* Chrome, Safari, Opera */
      transform: rotate(0deg);
      transform-origin: 20px 10px;
      top: -30px;
      right: -55px;

      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    .base {
      top: -50px;
      right: -90px;
      position: absolute;
    }
    .cover {
      position: absolute;
      width: 100%;
      text-align: center;
      height: 300px;

      .mask {
        position: absolute;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        margin-top: -86px;
        margin-left: -80px;
        overflow:hidden;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border: 3px solid #f0f0f0;

        img {
          width: 100%;
          scale: 1.1;
        }
      }
    }
  }
  .patefon.active {
    .recored, .mask {
      // float: left;
      // margin: 0 auto;
      // position: absolute;

      -webkit-animation: spin 4s linear infinite;
      -moz-animation: spin 4s linear infinite;
      animation: spin 4s linear infinite;
    }
    .pinter {
      -ms-transform: rotate(20deg);
      -webkit-transform: rotate(20deg);
      transform: rotate(20deg);
    }
  }
  .fullscreen {
    position: fixed;
    z-index: 250;
    right: 20px;
    top: 28px;
    font-size: 30px;
    font-weight: 300;
    line-height: 30px;
    height: 40px;
    background: none;
    border: 0;
    cursor: pointer;
    color: white;
  }
  .meta {
    position: absolute;
    bottom: 100px;
    left: 0;
    width: 100%;
    h1 {
      font-size: 2.5em;
      margin: 10px 0;
    }
    h2 {
      font-size: 1.5em;
      margin: 0 0 40px 0;
    }
    h1, h2 {
      text-transform: capitalize;
    }
    .controls {
      width: 300px;
      margin: 0 auto;
      padding: 0;
      height: 85px;
      text-align: center;
      button {
        list-style-type: none;
        float: left;
        width: 100px;
        color: white;
        background: none;
        border: 0;
        height: 85px;
        line-height: 130px;
        cursor: pointer;
      }
      button:hover {
        color: yellow;
      }
      button.inactive {
        visibility: hidden;
      }
    }
    .volume {
      height: 20px;
      margin-top: 20px;
      padding: 0 30%;
      .rc-slider {
        width: 200px;
        margin: 0 auto;
      }
    }
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 67%;
  background: rgba(240, 240, 240, 0.95);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  .station_description {
    margin-left: 80px;
    overflow: hidden;
    height: 130px;

    h2 {
      text-align: right;
      font-size: 27px;
      font-weight: 300;
      padding: 25px 40px;
      margin: 0;
      line-height: 40px;
    }
  }
  .fb {
    float: left;
    padding: 20px;
    width: 80px;
    height: 100px;
    overflow: hidden;
  }
}

.mobile {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  padding: 10px 15px 15px 15px;
  font-weight: normal;
  background-color: #132f54;
  margin-bottom: 20px;
  box-sizing: border-box;
  box-shadow: 0 3px 5px #000;
  letter-spacing: 1px;
  color: rgb(251, 255, 0);
  h2 {
    line-height: 30px;
    margin-bottom: 50px;
  }
  .logo {
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
    img {
      width: 100px;
    }
  }
  img {
    width: 300px;
  }
  .appstore {
    width: 270px;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    color: white; 
    background: rgb(25, 25, 25) ;
  }
  .header {
    background: rgba(15, 15, 15, 0.95);
  }

  .picker .block .gap .tile.active {
    border-color: rgb(216, 231, 8);
    box-shadow: 0 0 5px 5px rgba(87, 87, 87, 0.623);
  }

  .player {
    color: #000;
    .meta .controls button {
      color: #000;
    }
  }
}

/*
   Animation example, for spinners
*/
.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.clear {
  clear: both;
}